import { Component, ContentChild, Input, Optional, TemplateRef, input } from '@angular/core';
import { ShellComponent } from '@app/shell/shell.component';
import { TgToolbarControlsDirective } from './tg-toolbar-controls.directive';
import { TgToolbarHeaderDirective } from './tg-toolbar-header.directive';
import { TgToolbarRightControlsDirective } from './tg-toolbar-right-controls.directive';

@Component({
  selector: 'tg-toolbar',
  templateUrl: './tg-toolbar.component.html',
  styleUrl: './tg-toolbar.component.scss',
})
export class TgToolbarComponent {
  constructor(@Optional() private shell: ShellComponent) {}
  @ContentChild(TgToolbarControlsDirective, { read: TemplateRef, static: true })
  controls: TemplateRef<any>;
  @ContentChild(TgToolbarRightControlsDirective, { read: TemplateRef, static: true })
  rightControls: TemplateRef<any>;
  @ContentChild(TgToolbarHeaderDirective, { read: TemplateRef, static: true })
  header: TemplateRef<any>;
  @Input('tbclass')
  tbclass: string;
  showFrill = input(false);
  showUser = input(true);
  showMenuToggle = input(true);
  hideTeams = input(true);

  createContext() {
    const toggleMenu = () => this.shell?.toggleNav();
    return {
      toggleMenu,
    };
  }
}
